import { render, staticRenderFns } from "./be-604-up-to-date-information.vue?vue&type=template&id=c39fc30a"
import script from "./be-604-up-to-date-information.vue?vue&type=script&lang=js"
export * from "./be-604-up-to-date-information.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports