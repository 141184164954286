<template>
  <div v-click-outside="outsideCollapse">
    <div
      style="box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05)"
      class="z-50 fixed flex justify-center items-center right-4 bottom-[90px] md:bottom-[96px] w-[64px] h-[64px] bg-[#ffbd00] rounded-full p-4"
    >
      <div
        v-if="!open"
        class="absolute w-2.5 h-2.5 bg-orange-500 top-[7px] right-[2px] rounded-full"
      ></div>
      <img
        v-if="!open"
        @mousedown="show()"
        :src="`${$paths.assetsUrl}img/gift.svg`"
        loading="lazy"
        alt="gift icon"
        width="auto"
        height="auto"
        class="cursor-pointer"
      />
      <img
        v-else
        @mousedown="hide()"
        :src="`${$paths.assetsUrl}img/x.svg`"
        loading="lazy"
        alt="close icon"
        width="auto"
        height="auto"
        class="cursor-pointer"
      />
    </div>
    <div
      :class="{ hidden: !open }"
      class="pa-spring-campaign-box-shadow z-50 fixed top-auto max-w-[400px] md:px-0 px-5 right-0 md:right-4 bottom-[165px] md:bottom-[170px]"
    >
      <div class="bg-white p-5 md:p-30px rounded-[4px]">
        <h2
          class="pa-lh-normal font-cocogoose text-base"
          v-html="
            $i18n('general.spring-wash-car-campaign-the-prize-description')
          "
        ></h2>
        <p class="pa-lh-normal mt-2.5 text-md md:text-base">
          {{ $i18n('general.spring-wash-car-campaign-the-prize-date') }}
        </p>
        <span class="pa-lh-normal mt-2.5 text-[12px]">
          {{
            $i18n(
              'general.spring-wash-car-campaign-the-prize-terms-and-conditions'
            )
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    show() {
      this.open = !this.open;
      if (this.isMobile) {
        this.$store.commit('highlight/show', this.$el);
      }
      dataLayer.push({
        event: 'experiment_data',
        dl_experiment_id: '52/24',
        dl_experiment_action: 'floating_icon_click',
        dl_experiment_value: '',
      });
    },
    hide() {
      this.open = !this.open;
      if (this.isMobile) this.$store.dispatch('highlight/hide');
      dataLayer.push({
        event: 'experiment_data',
        dl_experiment_id: '52/24',
        dl_experiment_action: 'close_message_click',
        dl_experiment_value: '',
      });
    },
    outsideCollapse() {
      if (this.open) {
        this.hide();
      }
    },
  },
  computed: {
    isMobile() {
      if (process.server) {
        return true;
      }
      return this.$screens({ xs: false }, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.pa-lh-normal {
  line-height: normal;
}
@media only screen and (min-width: 990px) {
  .pa-spring-campaign-box-shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
