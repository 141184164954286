<template>
  <div class="xs:container px-5 sm:px-10 pt-10 pb-12 md:pt-[30px]">
    <div class="flex justify-center">
      <div
        class="flex justify-between items-center bg-[url('https://assets.parkos.com/assets/img/spring-banner-mobile-2.png')] md:bg-[url('https://assets.parkos.com/assets/img/group-16.png')] bg-no-repeat bg-center bg-cover max-w-[987px] w-full text-white py-[25px] px-[20px] md:py-[20px] md:px-[30px] rounded-md"
      >
        <div class="relative hidden md:block mr-[30px]">
          <svg
            width="142"
            height="142"
            viewBox="0 0 142 142"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m71 134.34-11.107 6.786-8.466-9.886-12.66 3.021-4.997-12.018-12.975-1.038-1.038-12.975-12.018-4.997 3.021-12.66-9.886-8.466L7.66 71 .874 59.893l9.886-8.466-3.021-12.66 12.018-4.997 1.038-12.975 12.975-1.038 4.997-12.018 12.66 3.021L59.893.874 71 7.66 82.107.874l8.466 9.886 12.66-3.021 4.997 12.018 12.975 1.038 1.038 12.975 12.018 4.997-3.021 12.66 9.886 8.466L134.34 71l6.786 11.107-9.886 8.466 3.021 12.66-12.018 4.997-1.038 12.975-12.975 1.038-4.997 12.018-12.66-3.021-8.466 9.886z"
              fill="#F06B1F"
              fill-rule="evenodd"
            ></path>
          </svg>
          <div
            style="line-height: 1.25"
            class="w-[66px] h-[70px] leading-5 overflow-hidden text-sm font-bold font-sans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <p class="text-center text-[31px] font-cocogoose font-normal">
              {{ $i18n('general.spring-wash-car-campaign-the-prize') }}
            </p>
            <p class="text-center">
              {{ $i18n('general.spring-wash-car-campaign-the-prize-text') }}
            </p>
          </div>
        </div>
        <div style="line-height: 1.25" class="w-full">
          <h2
            class="font-cocogoose text-base md:text-[24px]"
            v-html="
              $i18n('general.spring-wash-car-campaign-the-prize-description')
            "
          ></h2>
          <p class="mt-2.5 text-md md:text-[18px]">
            {{ $i18n('general.spring-wash-car-campaign-the-prize-date') }}
          </p>
          <a
            role="button"
            tabindex="0"
            class="block md:hidden mr-[30px] mb-2.5 md:mb-0 mt-2.5 font-cocogoose text-[15px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-7 py-4"
            @click="ctaTrigger"
          >
            <span>
              {{ $i18n('general.spring-wash-car-campaign-the-prize-reserve') }}
            </span>
          </a>
          <span class="mt-2.5 text-[12px] text-white">
            {{
              $i18n(
                'general.spring-wash-car-campaign-the-prize-terms-and-conditions'
              )
            }}
          </span>
        </div>
        <a
          role="button"
          tabindex="0"
          class="hidden md:block mr-[30px] w-full mt-2.5 font-cocogoose text-[15px] overflow-hidden overflow-ellipsis hover:bg-blaze-orange-500 bg-orange-500 text-white rounded-md text-center cursor-pointer whitespace-nowrap max-w-min px-7 py-4"
          @click="ctaTrigger"
        >
          <span>
            {{
              $i18n('general.spring-wash-car-campaign-the-prize-reserve')
            }}</span
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    ctaTrigger() {
      document.querySelector('.search-form').scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
      setTimeout(() => {
        document
          .querySelector(
            '.search-form > div > div > div.flex > div div[role="button"]'
          )
          .click();
      }, 20);

      dataLayer.push({
        event: 'experiment_data',
        dl_experiment_id: '52/24',
        dl_experiment_action: 'cta_click',
        dl_experiment_value: '',
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
