<template>
  <div class="faq-content">
    <div class="faq-content__dropdown-box">
      <details
        v-for="(tab, index) in items"
        :key="index"
        class="faq-content__dropdown-box__item"
        name="faq"
      >
        <summary class="flex justify-between cursor-pointer">
          <h2
            class="font-sans font-bold select-none m-0 mr-10 flex items-center faq-content__dropdown-box__item__title text-sm md:text-base"
          >
            {{ tab.title }}
          </h2>
          <img
            class="mx-2 faq-content__dropdown-box__item__chevron-down"
            :src="`${$paths.assetsUrl}img/static-pages/chevron-down.svg`"
            alt="chevron"
          />
        </summary>
        <div class="faq-content__dropdown-box__item__content">
          <div class="pt-2 pb-6" v-html="tab.content"></div>
        </div>
      </details>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.faq-content {
  &__dropdown-box {
    &__item {
      summary {
        cursor: pointer;
        list-style: none;
        padding: 0;
        margin: 0;
      }

      &__title {
        @apply text-md md:text-base;
        min-height: 3.5rem;
      }

      &__chevron-down {
        transition: 0.3s ease-in-out;
        width: 24px;
      }

      &__content {
        overflow: hidden;
        transition: 0.3s ease-in-out;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply text-md md:text-base;
        }

        a {
          @apply text-blue-500 hover:underline;
        }

        ul {
          @apply pl-[20px] m-[20px] mr-0 list-disc list-outside;
        }

        p {
          @apply my-[16px] text-md md:text-base;
        }

        .link-button {
          @apply m-[20px];

          a {
            @apply w-full block p-3 rounded bg-orange-500 hover:bg-orange-600 text-white font-semibold hover:no-underline text-center;
          }
        }
      }

      @apply border-t border-gray-200;
    }

    @apply border-b border-gray-200;
  }
}
[open] {
  .faq-content__dropdown-box__item__chevron-down {
    @apply rotate-180;
  }
}
</style>
