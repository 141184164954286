<template>
  <div class="xs:container md:px-10 md:pt-20 md:pb-10">
    <div
      v-if="shouldShowComponent"
      class="flex flex-col md:flex-row py-3 md:py-0 px-5 text-right md:text-start md:rounded border-athens md:border"
    >
      <div
        v-if="merchantCount"
        class="flex items-baseline justify-between md:justify-center border-athens md:border-r w-full md:w-1/4 py-2 md:py-8"
      >
        <IconCar class="mr-8 max-w-[20px]" />
        <div class="md:max-w-[180px]">
          <div class="min-w-min leading-none">
            {{ $i18n('merchant.parking-spots') }}
          </div>
          <div class="leading-none mt-2 md:mt-6">
            {{ merchantCount }}
          </div>
        </div>
      </div>

      <div
        v-if="topRatedParking"
        class="flex items-baseline justify-between md:justify-center border-athens md:border-r w-full md:w-1/4 py-2 md:py-8"
      >
        <IconMessage class="mr-8 max-w-[20px]" />
        <div class="md:max-w-[180px]">
          <div class="min-w-min leading-none">
            {{ $i18n('search.sort-by-best-reviews') }}
          </div>
          <div class="leading-none mt-2 md:mt-6">
            <a
              :href="`//${$paths.urlEnvPrefix}${$paths.host}/${airportData.airport.slug}/${topRatedParking.slug}.html`"
            >
              {{ topRatedParking.name }}
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="cheapestParking"
        class="flex items-baseline justify-between md:justify-center border-athens md:border-r w-full md:w-1/4 py-2 md:py-8"
      >
        <IconCheckmark class="mr-8 max-w-[20px]" />
        <div class="md:max-w-[180px]">
          <div class="min-w-min leading-none">
            {{ $i18n('general.cheap') }}
          </div>
          <div class="leading-none mt-2 md:mt-6">
            <a
              :href="`//${$paths.urlEnvPrefix}${$paths.host}/${airportData.airport.slug}/${cheapestParking.slug}.html`"
            >
              {{ cheapestParking.name }}
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="closestParking"
        class="flex items-baseline justify-between md:justify-center w-full md:w-1/4 py-2 md:py-8"
      >
        <IconNorth class="mr-8 max-w-[20px]" />
        <div class="md:max-w-[180px]">
          <div class="min-w-min leading-none">
            {{ $i18n('search.sort-by-closest') }}
          </div>
          <div class="leading-none mt-2 md:mt-6">
            <a
              :href="`//${$paths.urlEnvPrefix}${$paths.host}/${airportData.airport.slug}/${closestParking.slug}.html`"
            >
              {{ closestParking.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCar from '~/components/icons/info/IconCar.vue';
import IconNorth from '~/components/icons/info/IconCompassNorth.vue';
import IconCheckmark from '~/components/icons/info/IconCheckmark.vue';
import IconMessage from '~/components/icons/info/IconMessage.vue';

export default {
  components: {
    IconCar,
    IconNorth,
    IconCheckmark,
    IconMessage,
  },
  computed: {
    airportData() {
      return this.$store.state.airportData;
    },
    extendedAirportData() {
      return this.airportData.airportExtendedData;
    },
    topRatedParking() {
      if (!this.extendedAirportData['best-rated']) {
        return null;
      }
      return this.extendedAirportData['best-rated'].merchant;
    },
    cheapestParking() {
      if (!this.extendedAirportData.cheapest) {
        return null;
      }
      return this.extendedAirportData.cheapest.merchant;
    },
    closestParking() {
      if (!this.extendedAirportData.closest) {
        return null;
      }
      return this.extendedAirportData.closest.merchant;
    },
    merchantCount() {
      return this.extendedAirportData['merchant-count'] || 0;
    },
    shouldShowComponent() {
      return (
        this.airportData
        && this.extendedAirportData
        && (this.merchantCount
          || this.closestParking
          || this.cheapestParking
          || this.topRatedParking)
      );
    },
  },
  mounted() {
    this.polyfillSafari();
    window.addEventListener('popstate', () => {
      this.polyfillSafari();
    });
  },
  methods: {
    polyfillSafari() {
      setTimeout(() => {
        if (!this.$refs.grid) {
          return;
        }
        this.$refs.grid.style.height = 'min-content';
        setTimeout(() => {
          this.$refs.grid.style.height = 'auto';
        }, 100);
      });
    },
  },
};
</script>

<style lang="scss"></style>
