<template>
  <style>
    @media (max-width: 426px) {
    #__nuxt {
    font-size: 14px;
    }

    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.md\:pb-40.relative
    > div:nth-child(3)
    > h1 {
    font-size: 16px;
    }

    #__layout > div > main > div > div.airport-page__breadcrumbs > div {
    font-size: 12px;
    }
    #__layout
    > div
    > main
    > div
    > div.information-table.md\:hidden
    > div
    > div {
    font-size: 16px;
    }

    #__layout
    > div
    > main
    > div
    > div.md\:rounded.border-athens.border.md\:px-7.py-5.font-sans.md\:hidden
    > div
    > h3 {
    font-size: 16px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__content
    > div.airport-page__content-data
    h2 {
    font-size: 16px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__reviews-section
    > div
    > div.flex.flex-row.place-content-between.place-items-center.font-sans.mt-5.justify-between.flex-wrap
    > div {
    font-size: 16px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__reviews-section
    > div
    > div.-mx-2\.5.md\:-mx-3\.75
    > div
    > div
    > div
    > div
    > div.slick-slide.slick-active.slick-center.slick-current
    > div
    > div
    > div
    > div.gap-x-2.border-b.border-athens.pb-3
    > div.xxs\:text-md.md\:text-base.text-sm.text-manatee.w-full {
    font-size: 12px;
    }
    #__layout
    > div
    > main
    > div
    > div.airport-page__reviews-section
    > div
    > div.-mx-2\.5.md\:-mx-3\.75
    > div
    > div
    > div
    > div
    > div.slick-slide.slick-active.slick-center.slick-current
    > div
    > div
    > div
    > div.review-card__content-wrapper.border-b.border-athens.pb-3.box-content.relative.h-\[110px\]
    > div
    > div {
    height: 85px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__reviews-section
    > div
    > div.-mx-2\.5.md\:-mx-3\.75
    > div
    > div
    > div
    > div
    > div.slick-slide.slick-active.slick-center.slick-current
    > div
    > div
    > div
    > div.flex.place-content-between.place-items-center
    > div.flex.items-center
    > div.font-bold.xxs\:text-base.text-md.lowercase {
    font-size: 12px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__reviews-section
    > div
    > div.-mx-2\.5.md\:-mx-3\.75
    > div
    > div
    > div
    > div
    > div.slick-slide.slick-active.slick-center.slick-current
    > div
    > div
    > div
    > div.flex.place-content-between.place-items-center
    > div.text-manatee.xxs\:text-md.md\:text-base.text-sm {
    font-size: 12px;
    }
    #__layout
    > div
    > main
    > div
    > div.airport-page__parking-list
    > div
    > div
    > h2 {
    font-size: 16px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__parking-list
    > div
    > div
    > div
    a
    > div.merchants__card__price {
    font-size: 14px;
    }

    #__layout
    > div
    > main
    > div
    > div.airport-page__faq-container
    > div
    > div.flex.flex-col.sm\:flex-row.place-content-between.sm\:items-center
    > h3 {
    font-size: 16px;
    }

    #airports > div > div > div.usps__title {
    font-size: 16px;
    }

    #__layout
    > div
    > div
    > div.relative.support-wrapper.text-white.py-10.xs\:py-20
    > div.xs\:container.px-5.sm\:px-10
    > div
    > div.text-3xl.font-cocogoose {
    font-size: 16px;
    }
    #__layout
    > div
    > div
    > div.font-sans.text-white.bg-blue-600.pb-10.pt-4.sm\:py-20
    > div
    > div
    > div.md\:order-1.order-2.mt-6.sm\:mt-0
    > div
    > div.cms-payment-icons
    > div
    > div
    > div
    > p {
    font-size: 12px;
    }
    #__layout
    > div
    > div
    > div.font-sans.text-white.bg-blue-600.pb-10.pt-4.sm\:py-20
    > div
    > div
    > div.md\:order-1.order-2.mt-6.sm\:mt-0
    > div
    > div.mt-5.sm\:mt-0.md\:mt-5
    > div.text-md.md\:text-base {
    font-size: 12px;
    }
    #__layout
    > div
    > div
    > div.bg-chathams-blue-900.py-6.text-white.font-sans
    > div
    > div
    * {
    font-size: 12px !important;
    }
    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.relative.md\:pb-40
    > div.flex.flex-col.md\:block
    > div.inline-flex.flex-col.relative.md\:pr-16
    > div
    > div.sm\:border-r.border-gray-300.border-b.sm\:border-b-0.pb-5.sm\:pb-0.sm\:order-1.lg\:order-2.sm\:pr-4.md\:pr-7
    > div
    > div.mobile-date-picker.block.md\:hidden.xs\:w-full
    > div.w-125px.md\:w-auto.text-gray-500.whitespace-nowrap.text-ellipsis.overflow-hidden.overflow-ellipsis
    > span,
    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.relative.md\:pb-40
    > div.flex.flex-col.md\:block
    > div.inline-flex.flex-col.relative.md\:pr-16
    > div
    > div.sm\:order-2.lg\:order-3.md\:pr-2
    > div
    > div.mobile-date-picker.block.md\:hidden.xs\:w-full
    > div.w-125px.xs\:w-auto.text-gray-500.whitespace-nowrap.text-ellipsis.overflow-hidden.overflow-ellipsis.text-gray-500
    > span {
    font-weight: 400;
    font-family: 'cocogoose', sans-serif;
    color: #000;
    }
    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.relative.md\:pb-40
    > div.flex.flex-col.md\:block
    > div.inline-flex.flex-col.relative.md\:pr-16
    > div
    > div.sm\:border-r.border-gray-300.border-b.sm\:border-b-0.pb-5.sm\:pb-0.sm\:order-1.lg\:order-2.sm\:pr-4.md\:pr-7
    > div
    > div.mobile-date-picker.block.md\:hidden.xs\:w-full
    > div.font-bold.flex.items-center,
    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.relative.md\:pb-40
    > div.flex.flex-col.md\:block
    > div.inline-flex.flex-col.relative.md\:pr-16
    > div
    > div.sm\:border-r.border-gray-300.border-b.sm\:border-b-0.pb-5.sm\:pb-0.sm\:order-1.lg\:order-2.sm\:pr-4.md\:pr-7
    > div
    > div.legacy-time-picker.relative.ml-auto.xs\:ml-0.px-1.mt-auto
    > select,
    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.relative.md\:pb-40
    > div.flex.flex-col.md\:block
    > div.inline-flex.flex-col.relative.md\:pr-16
    > div
    > div.sm\:order-2.lg\:order-3.md\:pr-2
    > div
    > div.mobile-date-picker.block.md\:hidden.xs\:w-full
    > div.font-bold.flex.items-center,
    #__layout
    > div
    > header
    > div.xs\:container.pt-83px.md\:pt-12.pb-18.px-5.md\:px-10.md\:py-20.relative.md\:pb-40
    > div.flex.flex-col.md\:block
    > div.inline-flex.flex-col.relative.md\:pr-16
    > div
    > div.sm\:order-2.lg\:order-3.md\:pr-2
    > div
    > div.legacy-time-picker.relative.ml-auto.xs\:ml-0.px-1.mt-auto
    > select {
    font-size: 14px;
    font-weight: 400;
    }
    #__layout
    > div
    > main
    > div
    > div.md\:rounded.border-athens.border.md\:px-7.py-5.font-sans.md\:hidden
    > div
    > span,
    #__layout
    > div
    > main
    > div
    > div.airport-page__content
    > div.airport-page__content-data
    > div.font-bold
    > h2 {
    font-size: 16px;
    font-family: 'cocogoose', 'sans-serif';
    font-weight: 400;
    }
    }
  </style>
</template>

<script>
export default {};
</script>

<style></style>
