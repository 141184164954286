<template>
    <img src="~/static/icons/info/checkmark.svg" loading="lazy" alt="info checkmark">
</template>

<script>
export default {

};
</script>

<style>

</style>
