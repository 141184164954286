<template>
    <img src="~/static/icons/info/message.svg" loading="lazy" alt="info message">
</template>

<script>
export default {

};
</script>

<style>

</style>
