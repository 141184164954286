<template>
  <div class="be-604-content">
    <div ref="content" class="relative" v-html="airportContent" />
    <div
      ref="newContent"
      class="mt-[60px] border-b border-athens"
    />
    <CaretDown
      ref="caretDownRef"
      class="toggle-info-component__caret stroke-current transition-all transform"
    />
  </div>
</template>

<script>
import CaretDown from '~/components/icons/IconCaretDown.vue';

export default {
  components: {
    CaretDown,
  },
  props: {
    airportContent: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const readMoreContent = this.$refs.content.querySelector('.cms-content');

    const readMoreText = document.createElement('div');
    readMoreText.className = 'text-blue-600 cursor-pointer hover:underline';
    readMoreText.innerText = 'Read the full description';

    const readMore = document.createElement('div');
    readMore.id = 'read-more';
    readMore.className = 'flex h-[82px] w-full absolute bottom-0 items-end';
    readMore.style.backgroundImage = 'linear-gradient(to top, #fff, rgba(255, 255, 255, 0.8) 160%)';
    readMore.style.position = 'absolute';
    readMore.style.bottom = '-30px';
    readMore.appendChild(readMoreText);

    readMoreContent.parentNode.insertBefore(readMore, readMoreContent);
    readMoreText.addEventListener('click', () => {
      readMoreContent.style.display = 'block';
      readMore.style.display = 'none';
    });

    const hideDescriptionText = document.createElement('div');
    hideDescriptionText.className = 'text-blue-600 cursor-pointer hover:underline';
    hideDescriptionText.innerText = 'Hide the full description';

    const hideDescription = document.createElement('div');
    hideDescription.id = 'read-less';
    hideDescription.appendChild(hideDescriptionText);

    readMoreContent.appendChild(hideDescriptionText);
    hideDescriptionText.addEventListener('click', () => {
      readMoreContent.style.display = 'none';
      readMore.style.display = 'flex';
    });

    readMoreContent.style.display = 'none';

    this.$refs.content
      .querySelectorAll('section:has(.h1)')
      .forEach((element) => {
        const newSection = document.createElement('div');
        const newSectionHeader = document.createElement('div');
        newSectionHeader.className = 'flex justify-between items-center py-[20px] border-t border-athens cursor-pointer';

        const newSectionArrow = document.createElement('div');
        newSectionArrow.append(this.$refs.caretDownRef.$el.cloneNode(true));
        const newSectionTitle = element.querySelector('h2');
        newSectionTitle.className = 'text-[18px] font-bold  ';

        newSectionHeader.addEventListener('click', () => {
          if (element.style.display === 'none') {
            newSectionArrow.classList.add('rotate-180');
            element.style.display = 'block';
          } else {
            element.style.display = 'none';
            newSectionArrow.classList.remove('rotate-180');
          }
        });

        newSectionHeader.appendChild(newSectionTitle);
        newSectionHeader.appendChild(newSectionArrow);

        newSection.appendChild(newSectionHeader);
        element.style.display = 'none';
        newSection.appendChild(element);
        this.$refs.newContent.appendChild(newSection);
      });
    this.$refs.caretDownRef.$el.style.display = 'none';
  },
};
</script>

<style lang="scss">
.be-604-content .h1,
.btn,
h2 {
  @apply font-cocogoose;
}
</style>
