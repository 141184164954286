<template>
  <div class="font-sans">
    <h3 class="md:text-[28px] text-lg font-cocogoose">
      {{ $i18n('additional.faq') }}
    </h3>
    <FaqDropdown :items="$store.state.faq" />
  </div>
</template>

<script>
import FaqDropdown from '~/components/faq/FaqDropdown.vue';

export default {
  components: {
    FaqDropdown,
  },
};
</script>
