<template>
    <img src="~/static/icons/info/compass.svg" loading="lazy" alt="info compass">
</template>

<script>
export default {

};
</script>

<style>

</style>
